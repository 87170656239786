*,::after,::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent
}

@-ms-viewport {
    width: device-width
}

article,aside,dialog,figcaption,figure,footer,header,hgroup,main,nav,section {
    display: block
}

body {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff
}

[tabindex="-1"]:focus {
    outline: 0!important
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,h2,h3,h4,h5,h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-original-title],abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

dl,ol,ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,ol ul,ul ol,ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

dfn {
    font-style: italic
}

b,strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #2d3963;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

a:hover {
    color: #151b2e;
    text-decoration: underline
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus,a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus {
    outline: 0
}

code,kbd,pre,samp {
    font-family: monospace,monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar
}

figure {
    margin: 0 0 1rem
}

img {
    vertical-align: middle;
    border-style: none
}

svg:not(:root) {
    overflow: hidden
}

table {
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    -webkit-border-radius: 0;
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button,input,optgroup,select,textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,input {
    overflow: visible
}

button,select {
    text-transform: none
}

[type=reset],[type=submit],button,html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox],input[type=radio] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0
}

input[type=date],input[type=datetime-local],input[type=month],input[type=time] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-cancel-button,[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none!important
}

@-ms-viewport {
    width: device-width
}

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar
}

*,::after,::before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 576px) {
    .container {
        max-width:540px
    }
}

@media (min-width: 768px) {
    .container {
        max-width:720px
    }
}

@media (min-width: 992px) {
    .container {
        max-width:960px
    }
}

@media (min-width: 1250px) {
    .container {
        max-width:1190px
    }
}

.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

.row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col,.col-1,.col-10,.col-11,.col-12,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-auto,.col-lg,.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-lg-auto,.col-md,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-md-auto,.col-sm,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-sm-auto,.col-xl,.col-xl-1,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px
}

.col {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
}

.col-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none
}

.col-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%
}

.col-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.col-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.col-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%
}

.col-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%
}

.col-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%
}

.col-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%
}

.col-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%
}

.col-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1
}

.order-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13
}

.order-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0
}

.order-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1
}

.order-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2
}

.order-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3
}

.order-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4
}

.order-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5
}

.order-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6
}

.order-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7
}

.order-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8
}

.order-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9
}

.order-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10
}

.order-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11
}

.order-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12
}

.offset-1 {
    margin-left: 8.33333%
}

.offset-2 {
    margin-left: 16.66667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333%
}

.offset-5 {
    margin-left: 41.66667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333%
}

.offset-8 {
    margin-left: 66.66667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333%
}

.offset-11 {
    margin-left: 91.66667%
}

@media (min-width: 576px) {
    .col-sm {
        -webkit-flex-basis:0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-sm-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-sm-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-sm-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-sm-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-sm-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-sm-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-sm-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-sm-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-sm-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-sm-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-sm-first {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1
    }

    .order-sm-last {
        -webkit-box-ordinal-group: 14;
        -webkit-order: 13;
        -ms-flex-order: 13;
        order: 13
    }

    .order-sm-0 {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0
    }

    .order-sm-1 {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
    }

    .order-sm-2 {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2
    }

    .order-sm-3 {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3
    }

    .order-sm-4 {
        -webkit-box-ordinal-group: 5;
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4
    }

    .order-sm-5 {
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5
    }

    .order-sm-6 {
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6
    }

    .order-sm-7 {
        -webkit-box-ordinal-group: 8;
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7
    }

    .order-sm-8 {
        -webkit-box-ordinal-group: 9;
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8
    }

    .order-sm-9 {
        -webkit-box-ordinal-group: 10;
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9
    }

    .order-sm-10 {
        -webkit-box-ordinal-group: 11;
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10
    }

    .order-sm-11 {
        -webkit-box-ordinal-group: 12;
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11
    }

    .order-sm-12 {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333%
    }

    .offset-sm-2 {
        margin-left: 16.66667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333%
    }

    .offset-sm-5 {
        margin-left: 41.66667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333%
    }

    .offset-sm-8 {
        margin-left: 66.66667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333%
    }

    .offset-sm-11 {
        margin-left: 91.66667%
    }
}

@media (min-width: 768px) {
    .col-md {
        -webkit-flex-basis:0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-md-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-md-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-md-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-md-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-md-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-md-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-md-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-md-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-md-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-md-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-md-first {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1
    }

    .order-md-last {
        -webkit-box-ordinal-group: 14;
        -webkit-order: 13;
        -ms-flex-order: 13;
        order: 13
    }

    .order-md-0 {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0
    }

    .order-md-1 {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
    }

    .order-md-2 {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2
    }

    .order-md-3 {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3
    }

    .order-md-4 {
        -webkit-box-ordinal-group: 5;
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4
    }

    .order-md-5 {
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5
    }

    .order-md-6 {
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6
    }

    .order-md-7 {
        -webkit-box-ordinal-group: 8;
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7
    }

    .order-md-8 {
        -webkit-box-ordinal-group: 9;
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8
    }

    .order-md-9 {
        -webkit-box-ordinal-group: 10;
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9
    }

    .order-md-10 {
        -webkit-box-ordinal-group: 11;
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10
    }

    .order-md-11 {
        -webkit-box-ordinal-group: 12;
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11
    }

    .order-md-12 {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333%
    }

    .offset-md-2 {
        margin-left: 16.66667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333%
    }

    .offset-md-5 {
        margin-left: 41.66667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333%
    }

    .offset-md-8 {
        margin-left: 66.66667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333%
    }

    .offset-md-11 {
        margin-left: 91.66667%
    }
}

@media (min-width: 992px) {
    .col-lg {
        -webkit-flex-basis:0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-lg-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-lg-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-lg-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-lg-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-lg-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-lg-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-lg-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-lg-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-lg-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-lg-first {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1
    }

    .order-lg-last {
        -webkit-box-ordinal-group: 14;
        -webkit-order: 13;
        -ms-flex-order: 13;
        order: 13
    }

    .order-lg-0 {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0
    }

    .order-lg-1 {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
    }

    .order-lg-2 {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2
    }

    .order-lg-3 {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3
    }

    .order-lg-4 {
        -webkit-box-ordinal-group: 5;
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4
    }

    .order-lg-5 {
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5
    }

    .order-lg-6 {
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6
    }

    .order-lg-7 {
        -webkit-box-ordinal-group: 8;
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7
    }

    .order-lg-8 {
        -webkit-box-ordinal-group: 9;
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8
    }

    .order-lg-9 {
        -webkit-box-ordinal-group: 10;
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9
    }

    .order-lg-10 {
        -webkit-box-ordinal-group: 11;
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10
    }

    .order-lg-11 {
        -webkit-box-ordinal-group: 12;
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11
    }

    .order-lg-12 {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333%
    }

    .offset-lg-2 {
        margin-left: 16.66667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333%
    }

    .offset-lg-5 {
        margin-left: 41.66667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333%
    }

    .offset-lg-8 {
        margin-left: 66.66667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333%
    }

    .offset-lg-11 {
        margin-left: 91.66667%
    }
}

@media (min-width: 1250px) {
    .col-xl {
        -webkit-flex-basis:0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-xl-auto {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: none
    }

    .col-xl-1 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 8.33333%;
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-xl-2 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 16.66667%;
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xl-3 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 25%;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xl-4 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-xl-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 41.66667%;
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-xl-6 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 58.33333%;
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-xl-8 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.66667%;
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-xl-9 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 75%;
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 83.33333%;
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-xl-11 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 91.66667%;
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-xl-12 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 100%;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xl-first {
        -webkit-box-ordinal-group: 0;
        -webkit-order: -1;
        -ms-flex-order: -1;
        order: -1
    }

    .order-xl-last {
        -webkit-box-ordinal-group: 14;
        -webkit-order: 13;
        -ms-flex-order: 13;
        order: 13
    }

    .order-xl-0 {
        -webkit-box-ordinal-group: 1;
        -webkit-order: 0;
        -ms-flex-order: 0;
        order: 0
    }

    .order-xl-1 {
        -webkit-box-ordinal-group: 2;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1
    }

    .order-xl-2 {
        -webkit-box-ordinal-group: 3;
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2
    }

    .order-xl-3 {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
        -ms-flex-order: 3;
        order: 3
    }

    .order-xl-4 {
        -webkit-box-ordinal-group: 5;
        -webkit-order: 4;
        -ms-flex-order: 4;
        order: 4
    }

    .order-xl-5 {
        -webkit-box-ordinal-group: 6;
        -webkit-order: 5;
        -ms-flex-order: 5;
        order: 5
    }

    .order-xl-6 {
        -webkit-box-ordinal-group: 7;
        -webkit-order: 6;
        -ms-flex-order: 6;
        order: 6
    }

    .order-xl-7 {
        -webkit-box-ordinal-group: 8;
        -webkit-order: 7;
        -ms-flex-order: 7;
        order: 7
    }

    .order-xl-8 {
        -webkit-box-ordinal-group: 9;
        -webkit-order: 8;
        -ms-flex-order: 8;
        order: 8
    }

    .order-xl-9 {
        -webkit-box-ordinal-group: 10;
        -webkit-order: 9;
        -ms-flex-order: 9;
        order: 9
    }

    .order-xl-10 {
        -webkit-box-ordinal-group: 11;
        -webkit-order: 10;
        -ms-flex-order: 10;
        order: 10
    }

    .order-xl-11 {
        -webkit-box-ordinal-group: 12;
        -webkit-order: 11;
        -ms-flex-order: 11;
        order: 11
    }

    .order-xl-12 {
        -webkit-box-ordinal-group: 13;
        -webkit-order: 12;
        -ms-flex-order: 12;
        order: 12
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333%
    }

    .offset-xl-2 {
        margin-left: 16.66667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333%
    }

    .offset-xl-5 {
        margin-left: 41.66667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333%
    }

    .offset-xl-8 {
        margin-left: 66.66667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333%
    }

    .offset-xl-11 {
        margin-left: 91.66667%
    }
}

.d-none {
    display: none!important
}

.d-inline {
    display: inline!important
}

.d-inline-block {
    display: inline-block!important
}

.d-block {
    display: block!important
}

.d-table {
    display: table!important
}

.d-table-row {
    display: table-row!important
}

.d-table-cell {
    display: table-cell!important
}

.d-flex {
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important
}

.d-inline-flex {
    display: -webkit-inline-box!important;
    display: -webkit-inline-flex!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important
}

@media (min-width: 576px) {
    .d-sm-none {
        display:none!important
    }

    .d-sm-inline {
        display: inline!important
    }

    .d-sm-inline-block {
        display: inline-block!important
    }

    .d-sm-block {
        display: block!important
    }

    .d-sm-table {
        display: table!important
    }

    .d-sm-table-row {
        display: table-row!important
    }

    .d-sm-table-cell {
        display: table-cell!important
    }

    .d-sm-flex {
        display: -webkit-box!important;
        display: -webkit-flex!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-sm-inline-flex {
        display: -webkit-inline-box!important;
        display: -webkit-inline-flex!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display:none!important
    }

    .d-md-inline {
        display: inline!important
    }

    .d-md-inline-block {
        display: inline-block!important
    }

    .d-md-block {
        display: block!important
    }

    .d-md-table {
        display: table!important
    }

    .d-md-table-row {
        display: table-row!important
    }

    .d-md-table-cell {
        display: table-cell!important
    }

    .d-md-flex {
        display: -webkit-box!important;
        display: -webkit-flex!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-md-inline-flex {
        display: -webkit-inline-box!important;
        display: -webkit-inline-flex!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display:none!important
    }

    .d-lg-inline {
        display: inline!important
    }

    .d-lg-inline-block {
        display: inline-block!important
    }

    .d-lg-block {
        display: block!important
    }

    .d-lg-table {
        display: table!important
    }

    .d-lg-table-row {
        display: table-row!important
    }

    .d-lg-table-cell {
        display: table-cell!important
    }

    .d-lg-flex {
        display: -webkit-box!important;
        display: -webkit-flex!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-lg-inline-flex {
        display: -webkit-inline-box!important;
        display: -webkit-inline-flex!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }
}

@media (min-width: 1250px) {
    .d-xl-none {
        display:none!important
    }

    .d-xl-inline {
        display: inline!important
    }

    .d-xl-inline-block {
        display: inline-block!important
    }

    .d-xl-block {
        display: block!important
    }

    .d-xl-table {
        display: table!important
    }

    .d-xl-table-row {
        display: table-row!important
    }

    .d-xl-table-cell {
        display: table-cell!important
    }

    .d-xl-flex {
        display: -webkit-box!important;
        display: -webkit-flex!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-xl-inline-flex {
        display: -webkit-inline-box!important;
        display: -webkit-inline-flex!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }
}

@media print {
    .d-print-none {
        display: none!important
    }

    .d-print-inline {
        display: inline!important
    }

    .d-print-inline-block {
        display: inline-block!important
    }

    .d-print-block {
        display: block!important
    }

    .d-print-table {
        display: table!important
    }

    .d-print-table-row {
        display: table-row!important
    }

    .d-print-table-cell {
        display: table-cell!important
    }

    .d-print-flex {
        display: -webkit-box!important;
        display: -webkit-flex!important;
        display: -ms-flexbox!important;
        display: flex!important
    }

    .d-print-inline-flex {
        display: -webkit-inline-box!important;
        display: -webkit-inline-flex!important;
        display: -ms-inline-flexbox!important;
        display: inline-flex!important
    }
}

.flex-row {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: row!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important
}

.flex-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: column!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important
}

.flex-row-reverse {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: row-reverse!important;
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important
}

.flex-column-reverse {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: column-reverse!important;
    -ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important
}

.flex-wrap {
    -webkit-flex-wrap: wrap!important;
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important
}

.flex-nowrap {
    -webkit-flex-wrap: nowrap!important;
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important
}

.flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse!important;
    -ms-flex-wrap: wrap-reverse!important;
    flex-wrap: wrap-reverse!important
}

.justify-content-start {
    -webkit-box-pack: start!important;
    -webkit-justify-content: flex-start!important;
    -ms-flex-pack: start!important;
    justify-content: flex-start!important
}

.justify-content-end {
    -webkit-box-pack: end!important;
    -webkit-justify-content: flex-end!important;
    -ms-flex-pack: end!important;
    justify-content: flex-end!important
}

.justify-content-center {
    -webkit-box-pack: center!important;
    -webkit-justify-content: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important
}

.justify-content-between {
    -webkit-box-pack: justify!important;
    -webkit-justify-content: space-between!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important
}

.justify-content-around {
    -webkit-justify-content: space-around!important;
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important
}

.align-items-start {
    -webkit-box-align: start!important;
    -webkit-align-items: flex-start!important;
    -ms-flex-align: start!important;
    align-items: flex-start!important
}

.align-items-end {
    -webkit-box-align: end!important;
    -webkit-align-items: flex-end!important;
    -ms-flex-align: end!important;
    align-items: flex-end!important
}

.align-items-center {
    -webkit-box-align: center!important;
    -webkit-align-items: center!important;
    -ms-flex-align: center!important;
    align-items: center!important
}

.align-items-baseline {
    -webkit-box-align: baseline!important;
    -webkit-align-items: baseline!important;
    -ms-flex-align: baseline!important;
    align-items: baseline!important
}

.align-items-stretch {
    -webkit-box-align: stretch!important;
    -webkit-align-items: stretch!important;
    -ms-flex-align: stretch!important;
    align-items: stretch!important
}

.align-content-start {
    -webkit-align-content: flex-start!important;
    -ms-flex-line-pack: start!important;
    align-content: flex-start!important
}

.align-content-end {
    -webkit-align-content: flex-end!important;
    -ms-flex-line-pack: end!important;
    align-content: flex-end!important
}

.align-content-center {
    -webkit-align-content: center!important;
    -ms-flex-line-pack: center!important;
    align-content: center!important
}

.align-content-between {
    -webkit-align-content: space-between!important;
    -ms-flex-line-pack: justify!important;
    align-content: space-between!important
}

.align-content-around {
    -webkit-align-content: space-around!important;
    -ms-flex-line-pack: distribute!important;
    align-content: space-around!important
}

.align-content-stretch {
    -webkit-align-content: stretch!important;
    -ms-flex-line-pack: stretch!important;
    align-content: stretch!important
}

.align-self-auto {
    -webkit-align-self: auto!important;
    -ms-flex-item-align: auto!important;
    align-self: auto!important
}

.align-self-start {
    -webkit-align-self: flex-start!important;
    -ms-flex-item-align: start!important;
    align-self: flex-start!important
}

.align-self-end {
    -webkit-align-self: flex-end!important;
    -ms-flex-item-align: end!important;
    align-self: flex-end!important
}

.align-self-center {
    -webkit-align-self: center!important;
    -ms-flex-item-align: center!important;
    align-self: center!important
}

.align-self-baseline {
    -webkit-align-self: baseline!important;
    -ms-flex-item-align: baseline!important;
    align-self: baseline!important
}

.align-self-stretch {
    -webkit-align-self: stretch!important;
    -ms-flex-item-align: stretch!important;
    align-self: stretch!important
}

@media (min-width: 576px) {
    .flex-sm-row {
        -webkit-box-orient:horizontal!important;
        -webkit-box-direction: normal!important;
        -webkit-flex-direction: row!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-sm-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -webkit-flex-direction: column!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-sm-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -webkit-flex-direction: row-reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-sm-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -webkit-flex-direction: column-reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-sm-wrap {
        -webkit-flex-wrap: wrap!important;
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-sm-nowrap {
        -webkit-flex-wrap: nowrap!important;
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-sm-wrap-reverse {
        -webkit-flex-wrap: wrap-reverse!important;
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .justify-content-sm-start {
        -webkit-box-pack: start!important;
        -webkit-justify-content: flex-start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-sm-end {
        -webkit-box-pack: end!important;
        -webkit-justify-content: flex-end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-sm-center {
        -webkit-box-pack: center!important;
        -webkit-justify-content: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-sm-between {
        -webkit-box-pack: justify!important;
        -webkit-justify-content: space-between!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-sm-around {
        -webkit-justify-content: space-around!important;
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .align-items-sm-start {
        -webkit-box-align: start!important;
        -webkit-align-items: flex-start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-sm-end {
        -webkit-box-align: end!important;
        -webkit-align-items: flex-end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-sm-center {
        -webkit-box-align: center!important;
        -webkit-align-items: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-sm-baseline {
        -webkit-box-align: baseline!important;
        -webkit-align-items: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-sm-stretch {
        -webkit-box-align: stretch!important;
        -webkit-align-items: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-sm-start {
        -webkit-align-content: flex-start!important;
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-sm-end {
        -webkit-align-content: flex-end!important;
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-sm-center {
        -webkit-align-content: center!important;
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-sm-between {
        -webkit-align-content: space-between!important;
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-sm-around {
        -webkit-align-content: space-around!important;
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-sm-stretch {
        -webkit-align-content: stretch!important;
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-sm-auto {
        -webkit-align-self: auto!important;
        -ms-flex-item-align: auto!important;
        align-self: auto!important
    }

    .align-self-sm-start {
        -webkit-align-self: flex-start!important;
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-sm-end {
        -webkit-align-self: flex-end!important;
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-sm-center {
        -webkit-align-self: center!important;
        -ms-flex-item-align: center!important;
        align-self: center!important
    }

    .align-self-sm-baseline {
        -webkit-align-self: baseline!important;
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-sm-stretch {
        -webkit-align-self: stretch!important;
        -ms-flex-item-align: stretch!important;
        align-self: stretch!important
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        -webkit-box-orient:horizontal!important;
        -webkit-box-direction: normal!important;
        -webkit-flex-direction: row!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-md-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -webkit-flex-direction: column!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-md-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -webkit-flex-direction: row-reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-md-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -webkit-flex-direction: column-reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-md-wrap {
        -webkit-flex-wrap: wrap!important;
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-md-nowrap {
        -webkit-flex-wrap: nowrap!important;
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-md-wrap-reverse {
        -webkit-flex-wrap: wrap-reverse!important;
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .justify-content-md-start {
        -webkit-box-pack: start!important;
        -webkit-justify-content: flex-start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-md-end {
        -webkit-box-pack: end!important;
        -webkit-justify-content: flex-end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-md-center {
        -webkit-box-pack: center!important;
        -webkit-justify-content: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-md-between {
        -webkit-box-pack: justify!important;
        -webkit-justify-content: space-between!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-md-around {
        -webkit-justify-content: space-around!important;
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .align-items-md-start {
        -webkit-box-align: start!important;
        -webkit-align-items: flex-start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-md-end {
        -webkit-box-align: end!important;
        -webkit-align-items: flex-end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-md-center {
        -webkit-box-align: center!important;
        -webkit-align-items: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-md-baseline {
        -webkit-box-align: baseline!important;
        -webkit-align-items: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-md-stretch {
        -webkit-box-align: stretch!important;
        -webkit-align-items: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-md-start {
        -webkit-align-content: flex-start!important;
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-md-end {
        -webkit-align-content: flex-end!important;
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-md-center {
        -webkit-align-content: center!important;
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-md-between {
        -webkit-align-content: space-between!important;
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-md-around {
        -webkit-align-content: space-around!important;
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-md-stretch {
        -webkit-align-content: stretch!important;
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-md-auto {
        -webkit-align-self: auto!important;
        -ms-flex-item-align: auto!important;
        align-self: auto!important
    }

    .align-self-md-start {
        -webkit-align-self: flex-start!important;
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-md-end {
        -webkit-align-self: flex-end!important;
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-md-center {
        -webkit-align-self: center!important;
        -ms-flex-item-align: center!important;
        align-self: center!important
    }

    .align-self-md-baseline {
        -webkit-align-self: baseline!important;
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-md-stretch {
        -webkit-align-self: stretch!important;
        -ms-flex-item-align: stretch!important;
        align-self: stretch!important
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        -webkit-box-orient:horizontal!important;
        -webkit-box-direction: normal!important;
        -webkit-flex-direction: row!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-lg-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -webkit-flex-direction: column!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-lg-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -webkit-flex-direction: row-reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-lg-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -webkit-flex-direction: column-reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-lg-wrap {
        -webkit-flex-wrap: wrap!important;
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-lg-nowrap {
        -webkit-flex-wrap: nowrap!important;
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-lg-wrap-reverse {
        -webkit-flex-wrap: wrap-reverse!important;
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .justify-content-lg-start {
        -webkit-box-pack: start!important;
        -webkit-justify-content: flex-start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-lg-end {
        -webkit-box-pack: end!important;
        -webkit-justify-content: flex-end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-lg-center {
        -webkit-box-pack: center!important;
        -webkit-justify-content: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-lg-between {
        -webkit-box-pack: justify!important;
        -webkit-justify-content: space-between!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-lg-around {
        -webkit-justify-content: space-around!important;
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .align-items-lg-start {
        -webkit-box-align: start!important;
        -webkit-align-items: flex-start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-lg-end {
        -webkit-box-align: end!important;
        -webkit-align-items: flex-end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-lg-center {
        -webkit-box-align: center!important;
        -webkit-align-items: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-lg-baseline {
        -webkit-box-align: baseline!important;
        -webkit-align-items: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-lg-stretch {
        -webkit-box-align: stretch!important;
        -webkit-align-items: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-lg-start {
        -webkit-align-content: flex-start!important;
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-lg-end {
        -webkit-align-content: flex-end!important;
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-lg-center {
        -webkit-align-content: center!important;
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-lg-between {
        -webkit-align-content: space-between!important;
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-lg-around {
        -webkit-align-content: space-around!important;
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-lg-stretch {
        -webkit-align-content: stretch!important;
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-lg-auto {
        -webkit-align-self: auto!important;
        -ms-flex-item-align: auto!important;
        align-self: auto!important
    }

    .align-self-lg-start {
        -webkit-align-self: flex-start!important;
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-lg-end {
        -webkit-align-self: flex-end!important;
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-lg-center {
        -webkit-align-self: center!important;
        -ms-flex-item-align: center!important;
        align-self: center!important
    }

    .align-self-lg-baseline {
        -webkit-align-self: baseline!important;
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-lg-stretch {
        -webkit-align-self: stretch!important;
        -ms-flex-item-align: stretch!important;
        align-self: stretch!important
    }
}

@media (min-width: 1250px) {
    .flex-xl-row {
        -webkit-box-orient:horizontal!important;
        -webkit-box-direction: normal!important;
        -webkit-flex-direction: row!important;
        -ms-flex-direction: row!important;
        flex-direction: row!important
    }

    .flex-xl-column {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: normal!important;
        -webkit-flex-direction: column!important;
        -ms-flex-direction: column!important;
        flex-direction: column!important
    }

    .flex-xl-row-reverse {
        -webkit-box-orient: horizontal!important;
        -webkit-box-direction: reverse!important;
        -webkit-flex-direction: row-reverse!important;
        -ms-flex-direction: row-reverse!important;
        flex-direction: row-reverse!important
    }

    .flex-xl-column-reverse {
        -webkit-box-orient: vertical!important;
        -webkit-box-direction: reverse!important;
        -webkit-flex-direction: column-reverse!important;
        -ms-flex-direction: column-reverse!important;
        flex-direction: column-reverse!important
    }

    .flex-xl-wrap {
        -webkit-flex-wrap: wrap!important;
        -ms-flex-wrap: wrap!important;
        flex-wrap: wrap!important
    }

    .flex-xl-nowrap {
        -webkit-flex-wrap: nowrap!important;
        -ms-flex-wrap: nowrap!important;
        flex-wrap: nowrap!important
    }

    .flex-xl-wrap-reverse {
        -webkit-flex-wrap: wrap-reverse!important;
        -ms-flex-wrap: wrap-reverse!important;
        flex-wrap: wrap-reverse!important
    }

    .justify-content-xl-start {
        -webkit-box-pack: start!important;
        -webkit-justify-content: flex-start!important;
        -ms-flex-pack: start!important;
        justify-content: flex-start!important
    }

    .justify-content-xl-end {
        -webkit-box-pack: end!important;
        -webkit-justify-content: flex-end!important;
        -ms-flex-pack: end!important;
        justify-content: flex-end!important
    }

    .justify-content-xl-center {
        -webkit-box-pack: center!important;
        -webkit-justify-content: center!important;
        -ms-flex-pack: center!important;
        justify-content: center!important
    }

    .justify-content-xl-between {
        -webkit-box-pack: justify!important;
        -webkit-justify-content: space-between!important;
        -ms-flex-pack: justify!important;
        justify-content: space-between!important
    }

    .justify-content-xl-around {
        -webkit-justify-content: space-around!important;
        -ms-flex-pack: distribute!important;
        justify-content: space-around!important
    }

    .align-items-xl-start {
        -webkit-box-align: start!important;
        -webkit-align-items: flex-start!important;
        -ms-flex-align: start!important;
        align-items: flex-start!important
    }

    .align-items-xl-end {
        -webkit-box-align: end!important;
        -webkit-align-items: flex-end!important;
        -ms-flex-align: end!important;
        align-items: flex-end!important
    }

    .align-items-xl-center {
        -webkit-box-align: center!important;
        -webkit-align-items: center!important;
        -ms-flex-align: center!important;
        align-items: center!important
    }

    .align-items-xl-baseline {
        -webkit-box-align: baseline!important;
        -webkit-align-items: baseline!important;
        -ms-flex-align: baseline!important;
        align-items: baseline!important
    }

    .align-items-xl-stretch {
        -webkit-box-align: stretch!important;
        -webkit-align-items: stretch!important;
        -ms-flex-align: stretch!important;
        align-items: stretch!important
    }

    .align-content-xl-start {
        -webkit-align-content: flex-start!important;
        -ms-flex-line-pack: start!important;
        align-content: flex-start!important
    }

    .align-content-xl-end {
        -webkit-align-content: flex-end!important;
        -ms-flex-line-pack: end!important;
        align-content: flex-end!important
    }

    .align-content-xl-center {
        -webkit-align-content: center!important;
        -ms-flex-line-pack: center!important;
        align-content: center!important
    }

    .align-content-xl-between {
        -webkit-align-content: space-between!important;
        -ms-flex-line-pack: justify!important;
        align-content: space-between!important
    }

    .align-content-xl-around {
        -webkit-align-content: space-around!important;
        -ms-flex-line-pack: distribute!important;
        align-content: space-around!important
    }

    .align-content-xl-stretch {
        -webkit-align-content: stretch!important;
        -ms-flex-line-pack: stretch!important;
        align-content: stretch!important
    }

    .align-self-xl-auto {
        -webkit-align-self: auto!important;
        -ms-flex-item-align: auto!important;
        align-self: auto!important
    }

    .align-self-xl-start {
        -webkit-align-self: flex-start!important;
        -ms-flex-item-align: start!important;
        align-self: flex-start!important
    }

    .align-self-xl-end {
        -webkit-align-self: flex-end!important;
        -ms-flex-item-align: end!important;
        align-self: flex-end!important
    }

    .align-self-xl-center {
        -webkit-align-self: center!important;
        -ms-flex-item-align: center!important;
        align-self: center!important
    }

    .align-self-xl-baseline {
        -webkit-align-self: baseline!important;
        -ms-flex-item-align: baseline!important;
        align-self: baseline!important
    }

    .align-self-xl-stretch {
        -webkit-align-self: stretch!important;
        -ms-flex-item-align: stretch!important;
        align-self: stretch!important
    }
}

.w-25 {
    width: 25%!important
}

.w-50 {
    width: 50%!important
}

.w-75 {
    width: 75%!important
}

.w-100 {
    width: 100%!important
}

.h-25 {
    height: 25%!important
}

.h-50 {
    height: 50%!important
}

.h-75 {
    height: 75%!important
}

.h-100 {
    height: 100%!important
}

.mw-100 {
    max-width: 100%!important
}

.mh-100 {
    max-height: 100%!important
}

::-webkit-input-placeholder {
    color: #666
}

::-moz-placeholder {
    color: #666
}

:-ms-input-placeholder {
    color: #666
}

::-ms-input-placeholder {
    color: #666
}

::placeholder {
    color: #666
}

::-moz-selection {
    background-color: #51acf4;
    color: #fff
}

::selection {
    background-color: #51acf4;
    color: #fff
}

body {
    font-size: 16px;
    min-width: 320px;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    line-height: 1.28;
    overflow-x: hidden;
    color: #383838
}

body.index {
    background: #fff url(../images/bg-main.png) top no-repeat;
    -webkit-background-size: 100% auto;
    background-size: 100% auto
}

body.hide-scroll {
    height: 100vh;
    overflow: hidden
}

body input,body textarea {
    border: #666 1px solid;
    outline: 0
}

body input:focus:required:invalid,body textarea:focus:required:invalid {
    border-color: red
}

body input:required:valid,body textarea:required:valid {
    border-color: green
}

img,video {
    max-width: 100%;
    height: auto
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto
}

.img-responsive.img-width {
    width: 100%
}

.text-center {
    text-align: center
}

b,strong {
    font-weight: 700
}

.btn {
    display: inline-block;
    min-width: 240px;
    padding: 22.5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    font-size: 25px;
    line-height: 25px;
    min-height: 70px;
    background-color: #fff;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.btn:hover {
    text-decoration: none
}

.btn_shadow {
    position: relative;
    overflow: visible
}

.btn_shadow::after {
    content: '';
    position: absolute;
    left: 50%;
    right: 0;
    z-index: -1;
    bottom: 15px;
    width: 55%;
    height: 10px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-box-shadow: 0 0 40px 25px #2d3963;
    box-shadow: 0 0 40px 25px #2d3963;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.btn_shadow:hover::after {
    -webkit-box-shadow: 0 0 40px 25px transparent;
    box-shadow: 0 0 40px 25px transparent
}

.btn_transparent {
    position: relative;
    padding: 10px 50px 10px 15px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    border: 1px solid transparent;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.btn_transparent::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 12px;
    width: 23px;
    height: 16px;
    background-image: url(../images/icon-arrow-dark.png);
    -webkit-background-size: cover;
    background-size: cover;
    -webkit-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    transform: translate(0,-50%)
}

.btn_transparent:hover {
    /*color: #fff;
    border-color: #fff;*/
    text-decoration: none
}

.btn_transparent:hover::before {
    -webkit-animation-name: translateX;
    animation-name: translateX;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite
}

.btn_green {
    color: #fff;
    background-color: #4dcf95
}

.btn_green:hover {
    color: #fff;
    background-color: #44b784
}
.btn_purple,
.btn_purple:hover{
    color: #fff;
    background-color: #4d37be;
}
@media (max-width: 1249.98px) {
    .btn {
        min-width:200px;
        min-height: 60px;
        padding: 17.5px
    }
}

@media (max-width: 767.98px) {
    .btn {
        min-width:180px;
        min-height: 50px;
        padding: 12.5px;
        font-size: 20px
    }
}

@-webkit-keyframes translateX {
    0% {
        -webkit-transform: translate(0,-50%);
        transform: translate(0,-50%)
    }

    50% {
        -webkit-transform: translate(-10%,-50%);
        transform: translate(-10%,-50%)
    }

    100% {
        -webkit-transform: translate(0,-50%);
        transform: translate(0,-50%)
    }
}

@keyframes translateX {
    0% {
        -webkit-transform: translate(0,-50%);
        transform: translate(0,-50%)
    }

    50% {
        -webkit-transform: translate(-10%,-50%);
        transform: translate(-10%,-50%)
    }

    100% {
        -webkit-transform: translate(0,-50%);
        transform: translate(0,-50%)
    }
}

.lines {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    height: 3px;
    background-color: #fff;
    -webkit-box-shadow: 0 -6px 0 0 #fff,0 6px 0 0 #fff;
    box-shadow: 0 -6px 0 0 #fff,0 6px 0 0 #fff;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.lines::after,.lines::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: transparent;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.lines::before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.lines::after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.screen {
    padding: 100px 0 110px
}

.screen-title {
    margin: 0 auto 30px;
    width: 100%;
    text-align: center;
    font-size: 35px;
    line-height: 1.1;
    font-weight: 400;
    color: #383838
}

@media (max-width: 1249.98px) {
    .screen {
        padding:80px 0 90px
    }

    .screen-title {
        margin: 0 auto 25px;
        font-size: 40px
    }
}

@media (max-width: 991.98px) {
    .screen {
        padding:50px 0 60px
    }

    .screen-title {
        margin: 0 auto 20px;
        font-size: 30px
    }
}

@media (max-width: 767.98px) {
    .screen {
        padding:40px 0 50px
    }

    .screen-title {
        margin: 0 auto 15px;
        font-size: 26px
    }
}

.period-tabs-nav {
    margin: 0 auto 35px;
    padding: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.period-tabs-nav-li {
    display: inline-block;
    vertical-align: middle;
    list-style: none
}

.period-tabs-nav-li.is-active .period-tabs-nav-li__btn {
    color: #fff;
    background-color: #7fd16b
}

.period-tabs-nav-li__btn {
    min-width: 150px;
    text-align: center;
    font-size: 13px;
    line-height: 38px;
    font-weight: 400;
    color: #374568;
    border: 0;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
    cursor: pointer
}

.period-tabs-nav-li__btn:hover {
    color: #374568;
    background-color: #c2e9b9
}

.period-tabs-content {
    margin: 0;
    padding: 0;
    list-style: none
}

.period-tabs-content-li {
    display: none
}

.period-tabs-content-li.is-show {
    display: block
}

.header,.header_form {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    margin: 0 auto;
    padding-top: 25px;
    padding-bottom: 25px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: -webkit-calc((100% - 85% - 30px)/ 2 + 15px);
    padding-left: calc((100% - 85% - 30px)/ 2 + 15px);
    padding-right: -webkit-calc((100% - 85% - 30px)/ 2 + 15px);
    padding-right: calc((100% - 85% - 30px)/ 2 + 15px);
    max-width: 100%;
    width: 100%;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
    background-color: #fff;
}
.header_form{
    background-color: transparent;
}
.header_inner {
    -moz-box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    -webkit-box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
}
.main{padding-top: 106px;}

.header-link {
    position: relative;
    color: #383838;
    overflow: hidden
}

.header-link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 4px;
    background-color: #4d37be;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.header-link:hover::after {
    width: -webkit-calc(100% - 30px);
    width: calc(100% - 30px)
}

.header-nav-ul-li.is-active .header-link::after {
    width: -webkit-calc(100% - 30px);
    width: calc(100% - 30px)
}

.btn_transparent {
    color: #383838;
}

.lines {
    background-color: #383838;
    -webkit-box-shadow: 0 -6px 0 0 #383838,0 6px 0 0 #383838;
    box-shadow: 0 -6px 0 0 #383838,0 6px 0 0 #383838
}

.btn-mobile-menu_active .lines {
    background-color: #383838;
    -webkit-box-shadow: 0 -6px 0 0 #383838,0 6px 0 0 #383838;
    box-shadow: 0 -6px 0 0 #383838,0 6px 0 0 #383838
}

.btn-mobile-menu_active .lines::after,.btn-mobile-menu_active .lines::before {
    background-color: #383838!important
}

.header-logo {
    font-size: 34px;
    color: #fff;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.header-logo:hover {
    text-decoration: none
}

.header-logo__img {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 20px;
    max-width: 225px
}

.header-logo__img img {
    width: auto;
    height: auto
}

.header-nav-ul {
    margin: auto 0;
    padding: 0;
    list-style: none
}

.header-nav-ul-li {
    margin-left: 15px
}

.header-user-block {
    margin-left: 100px
}

.header-user-block .btn_transparent {
    margin-left: 15px
}

.header-link {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 5px 15px;
    font-size: 21px;
    font-weight: 400;
    color: #383838;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.header-link:hover {
    text-decoration: none
}

.header .btn-mobile-menu {
    display: none
}

@media (max-width: 1700px) {
    .header {
        max-width:90%;
        padding-top: 20px;
        padding-left: -webkit-calc((100% - 90% - 30px)/ 2 + 15px);
        padding-left: calc((100% - 90% - 30px)/ 2 + 15px);
        padding-right: -webkit-calc((100% - 90% - 30px)/ 2 + 15px);
        padding-right: calc((100% - 90% - 30px)/ 2 + 15px);
        padding-bottom: 20px;
    }
    .main{padding-top: 96px;}
    .header-nav-ul-li {
        margin-left: 10px
    }

    .header-user-block {
        margin-left: 60px
    }
}

@media (max-width: 1500px) {
    .header {
        max-width:95%;
        padding-top: 15px;
        padding-left: -webkit-calc((100% - 95% - 30px)/ 2 + 15px);
        padding-left: calc((100% - 95% - 30px)/ 2 + 15px);
        padding-right: -webkit-calc((100% - 95% - 30px)/ 2 + 15px);
        padding-right: calc((100% - 95% - 30px)/ 2 + 15px);
        padding-bottom: 15px;
    }
    .main{padding-top: 75px;}

    .header-logo {
        font-size: 28px
    }

    .header-logo__img {
        max-width: 180px
    }

    .header-user-block .btn_transparent {
        margin-left: 10px;
        font-size: 18px
    }

    .header-link {
        font-size: 20px
    }
}

@media (max-width: 1400px) {
    .header {
        padding: 10px 15px;
        max-width: 100%;
    }
    .main{padding-top: 65px;}
    .header-nav-ul-li {
        margin-left: 5px;
    }

    .header-user-block {
        margin-left: 40px
    }

    .header-user-block .btn_transparent {
        margin-left: 5px
    }
}

@media (max-width: 1249.98px) {
    .header {
        padding:5px 15px;
    }
    .main{padding-top: 55px;}
    .header-logo {
        font-size: 24px
    }

    .header-nav-ul-li {
        margin-left: 0
    }

    .header-user-block {
        margin-left: 20px
    }

    .header-link {
        padding: 5px 10px;
        font-size: 18px
    }
}

@media (max-width: 991.98px) {
    .header {
        padding:5px 15px
    }
    .main{padding-top: 50px;}
    .header-logo {
        position: relative;
        z-index: 2;
        font-size: 22px
    }
    .header-logo__img{margin-left: 2px;}
    .header-links-js {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        padding: 50px 15px 30px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: 100vh;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        background-color: #f9f9f9;
        -webkit-background-size: 100% 200%;
        background-size: 100% 200%;
        -webkit-transform: translate(0,-100%);
        -ms-transform: translate(0,-100%);
        transform: translate(0,-100%);
        overflow: auto
    }

    .header-links-js_show {
        -webkit-transform: translate(0,0);
        -ms-transform: translate(0,0);
        transform: translate(0,0)
    }

    .header-nav {
        margin-top: auto
    }

    .header-user-block {
        margin: 0 auto auto
    }

    .header-link {
        margin-bottom: 15px
    }

    .header .btn-mobile-menu {
        position: relative;
        z-index: 2;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 22px;
        color: #fff;
        background-color: transparent;
        border: 0;
        outline: 0
    }

    .header .btn-mobile-menu .lines {
        width: 32px
    }

    .header .btn-mobile-menu_active .lines {
        background-color: transparent;
        -webkit-box-shadow: 0 0 0 transparent;
        box-shadow: 0 0 0 transparent
    }

    .header .btn-mobile-menu_active .lines::after,.header .btn-mobile-menu_active .lines::before {
        background-color: #fff
    }
}

.screen-main {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 100px;
    padding-bottom: 100px;
}

.screen-main-h1 {
    margin: 0 0 40px;
    font-size: 44px;
    line-height: 1.1;
    font-weight: 700;
}

.screen-main-h1__span {
    display: block
}

.screen-main__p {
    margin: 0 0 40px;
    font-size: 24px;
    font-weight: 300;
}

.screen-what {
    margin-bottom: 70px;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center
}

.screen-what__p {
    margin: 0 auto 25px;
    font-size: 18px;
    color: #5b6268
}

.screen-what__p:last-of-type {
    margin-bottom: 50px
}

.screen-what-video-wrapper {
    position: relative;
/*    padding: 30px 45px;
*/    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    /*background: url(../images/bg-video-top.png) center no-repeat;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%*/
    max-width: 100%;
}
.screen-what-video-wrapper__header{
    background-color: #dce3e5;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    position: relative;
}
.round_bullets{
    position: absolute;
    left: 20px;
    top:7px;
    height: 10px;
}
.round_bullets span{
    border-radius: 50%;
    background-color: #ffffff;
    height: 10px;
    width: 10px;
    display: inline-block;
    margin-right: 8px;
}
ul.video-play__tabs{
    margin: 0px;
    padding: 7px 0px;
    list-style: none;
}
ul.video-play__tabs li{
    color: #383838;
    background: none;
    display: inline-block;
    margin: 0 20px;
    cursor: pointer;
    font-size: 15px;
}
ul.video-play__tabs li.active{
    border-bottom: 2px solid #383838;
}
ol.dev-tabs{
    counter-reset: myCounter;
    margin-top:1rem;
}
ol.dev-tabs li{
    list-style: none; 
    position: relative;
    cursor: pointer;
    margin-bottom: 30px;
    padding-left: 30px;
    font-size: 18px;
}
.screen-dev{padding: 50px 0px 0px;}
.sidebar{
    background-color: #f9f9fb;
    height: 100%;
    position: fixed;
    padding-top: 20px;
}
ol.dev-tabs li span{display: flex;align-items: center;height: 50px;}
ol.dev-tabs li:before {
    counter-increment: myCounter;
    content:counter(myCounter);
    font-size:25px;
    font-weight:400;
    color:#ffffff;
    margin-bottom:20px;
    position: absolute;
    left: -25px;
    top: 0px;
    height: 50px;
    width: 50px;
    text-align: center;
    background-color:#4e39c1;
    border-radius: 50%;
    line-height: 50px;
}
ol.dev-tabs li ul{
    list-style: none;
    padding: 0;
    margin: 20px 0px;
}
@media (max-width: 1400px) {
    ol.dev-tabs li:before{
        width: 25px;
        height: 25px;
        font-size: 15px;
        line-height: 25px;
    }
    ol.dev-tabs li{padding-left: 5px;font-size: 15px;}
    ol.dev-tabs li span{height: auto;}
}
ol.dev-tabs ul li:before{
    content:none;
}
.tab-content{display: none;}
.tab-content.active{
    display: inherit;
}
.screen-what-video-wrapper:after{
    content: '';
    position: absolute;
    left: 50%;
    right: 0;
    z-index: -1;
    bottom: 15px;
    width: 98%;
    height: 90%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-box-shadow: 0 0 40px 25px #f1f1f1;
    box-shadow: 0 0 40px 25px #f1f1f1;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}
.screen-what-video-wrapper__play {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    left: 50%;
    z-index: 2;
/*    background-color: rgba(93,173,247,.8);
*/    cursor: pointer
}

.video__video {
    display: block;
    width: -webkit-calc(100% - 1px);
    width: calc(100% - 1px);
    height: auto;
    margin: 0 auto;
    vertical-align: middle;
}

.video-play {
    opacity: 1;
    -webkit-transition: opacity .25s;
    -o-transition: opacity .25s;
    transition: opacity .25s
}

.video-play_hide {
    z-index: -1;
    opacity: 0
}

.video-play__button {
    width: 68px
}

.video-play__button img {
    display: inline-block;
    width: 100%;
    height: auto
}

.video-play__text {
    font-size: 45px;
    font-weight: 500;
    color: #fff
}
.tab-title{
    padding-top: 30px;
    margin-bottom: 30px;
    color:#4d37be;
    font-size: 22px;
    font-weight: 400;
}
.tab-title:before{
    content:'';
    height: 0px; 
    width: 0px;
    border: 8px solid;
    border-left-width:12px;
    border-color: 
        transparent
        transparent
        transparent
        #4d37be;
    display: inline-block;
}
.code-content{
    margin: 30px 0px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 3px 10px 0 rgba(0,0,0,0.15);
    box-shadow: 0 3px 10px 0 rgba(0,0,0,0.15);
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
}
.tab-content p{
    font-size: 18px;
}
.code-title{
    background-color: #f9f9f9;
    padding: 20px;
    font-weight: 300;
    font-size: 18px;
}
.code-list{
    background-color: #f9f9f9;
    padding: 20px;
    font-weight: 300;
    font-size: 18px;
}
.code-list:nth-child(even){
    background-color: #ffffff;
}
.code-list:before,.checked:before{
    content:'';
    display: inline-block;
    background: url(../images/checked.svg) no-repeat center center/100%;
    width:15px;
    height: 15px;
    margin-right: 5px;
}
.code-content table{width: 100%;}
.code-content table th,.code-content table td{
    padding: 10px 15px;
    font-weight: 400;
}
.code-content table td{font-size: 15px;color:#707070;}
.red{color:#ff7878;}
.code-content table th,.code-content table tr:nth-child(even){
    background-color: #f9f9f9;
}
.code-content table tr:nth-child(odd){
    background-color: #ffffff;
}
.screen-features {
    padding-top: 0
}

.screen-features .screen-title {
    margin-bottom: 60px;
    font-size: 50px;
}

.feature {
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
    padding: 30px 35px;
    box-sizting: border-box;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background-color: #fff;
    -webkit-box-shadow: 0 12px 35px 0 rgba(190,190,190,0);
    box-shadow: 0 12px 35px 0 rgba(190,190,190,0);
    border-bottom: 1px solid f5f5f9;
    -webkit-transition: -webkit-box-shadow .25s;
    transition: -webkit-box-shadow .25s;
    -o-transition: box-shadow .25s;
    transition: box-shadow .25s;
    transition: box-shadow .25s,-webkit-box-shadow .25s
}

.feature:not(:nth-last-of-type(1)):not(:nth-last-of-type(2)):not(:nth-last-of-type(3)) {
    border-bottom: 1px solid #f5f5f9
}

.feature:hover {
    z-index: 2;
    -webkit-box-shadow: 0 12px 35px 0 rgba(190,190,190,.33);
    box-shadow: 0 12px 35px 0 rgba(190,190,190,.33);
    border-bottom: 1px solid #fff
}

.feature:hover svg {
    fill: #4d37be
}

.feature-header {
    margin-bottom: 15px
}

.feature-header__icon {
    margin-right: 20px;
    width: 45px;
    text-align: center
}

.feature-header__icon img,.feature-header__icon svg {
    width: auto;
    height: auto;
    max-height: 35px;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.feature-header__icon svg {
    fill: #dbdbdb;
}

.feature-header__title {
    font-size: 20px;
    font-weight: 400;
    color: #383838;
}
.feature:hover .feature-header__title{
    color: #4d37be;
}
.feature-text {
    font-size: 16px;
    color: #383838;
}

.feature-text__p {
    margin: 0
}

@media (max-width: 1249.98px) {
    .screen-features .screen-title {
        margin-bottom:40px;
        font-size: 35px;
    }

    .feature {
        padding: 20px 25px
    }

    .feature-header {
        margin-bottom: 10px
    }
}

@media (max-width: 991.98px) {
    .feature:not(:nth-last-of-type(1)):not(:nth-last-of-type(2)) {
        border-bottom:1px solid #f5f5f9
    }
}

@media (max-width: 767.98px) {
    .screen-features .screen-title {
        margin-bottom:20px
    }

    .feature {
        padding: 10px 15px 15px
    }

    .feature-header {
        margin-bottom: 5px
    }
    .round_bullets{display: none;}
}

@media (max-width: 575.98px) {
    .feature {
        text-align:center
    }

    .feature:not(:nth-last-of-type(1)) {
        border-bottom: 1px solid #f5f5f9
    }

    .feature-header {
        text-align: center
    }
}

.screen-lid {
    padding-bottom: 50px;
    padding-top: 60px;
    text-align: center;
    background-color: #4d37be;
    color:#ffffff;
}

.screen-lid__title {
    margin: 0 auto 25px;
    font-size: 28px;
    color: #ffffff;
}

.screen-lid .btn {
    margin-bottom: 13px
}

.screen-lid__small-text {
    font-size: 21px;
    font-weight: 200;
    color:#ffffff;
}

@media (max-width: 1249.98px) {
    .screen-lid {
        padding:70px 0 50px
    }

    .screen-lid__title {
        margin-bottom: 20px;
        font-size: 30px
    }

    .screen-lid__small-text {
        font-size: 20px
    }
}

@media (max-width: 991.98px) {
    .screen-lid {
        padding:50px 0 40px
    }

    .screen-lid__title {
        margin-bottom: 15px;
    }

    .screen-lid .btn {
        margin-bottom: 10px
    }

    .screen-lid__small-text {
        font-size: 16px
    }
}

@media (max-width: 767.98px) {
    .screen-lid {
        padding:40px 0 30px
    }

    .screen-lid__title {
        margin-bottom: 10px;
        font-size: 20px
    }

    .screen-lid .btn {
        margin-bottom: 5px
    }

    .screen-lid__small-text {
        font-size: 14px
    }
}

.screen-different .screen-title {
    margin-bottom: 60px;
    font-size: 50px;
}

.different:not(:last-of-type) {
    margin-bottom: 80px
}

.different-content {
    text-align: center
}

.different-content__title {
    margin: 0 auto 30px;
    font-size: 30px;
    font-weight: 400;
}

.different-content__p {
    margin: 0;
    font-size: 20px;
    font-weight: 200;
}

@media (max-width: 1249.98px) {
    .screen-different .screen-title {
        margin-bottom:40px;
        font-size: 35px;
    }

    .different {
        margin-bottom: 60px
    }

    .different:last-of-type {
        margin-bottom: 0
    }

    .different-content__title {
        margin-bottom: 15px;
    }
}

@media (max-width: 991.98px) {
    .screen-different .screen-title {
        margin-bottom:30px
    }

    .different {
        margin-bottom: 40px
    }

    .different:last-of-type {
        margin-bottom: 0
    }

    .different-content__title {
        margin-bottom: 10px;
        font-size: 25px;
    }
    .different-content__p{
        font-size: 18px;
    }
}

@media (max-width: 767.98px) {
    .different-img {
        text-align:center
    }

    .different-img__img {
        display: inline-block;
        margin-bottom: 30px
    }

    .different-content__title {
        margin-bottom: 5px;
    }

}

@media (max-width: 575.98px) {
    .different {
        margin-bottom:40px
    }

    .different-content__title {
        margin-bottom: 5px;
        font-size: 32px;
    }
    .different-content__p{font-size: 16px;}
}

.screen-ready {
    position: relative;
    padding-bottom: 95px;
    text-align: center;
    overflow: hidden;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(245,245,245,1) 47%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(245,245,245,1) 47%,rgba(255,255,255,1) 100%);
    background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(245,245,245,1) 47%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 );
}

.screen-ready__title {
    font-size: 72px;
    font-weight: 700;
    color:#323232;
}

.screen-ready__text {
    margin: 0 0 55px;
    font-size: 29px;
    color:#323232;
}
.screen-ready .btn{
    margin-bottom: 13px;
}
.screen-ready__small-text {
    font-size: 21px;
    font-weight: 200;
    color:#323232;
}
@media (max-width: 1249.98px) {
    .screen-ready {
        padding:70px 0
    }

    .screen-ready__title {
        font-size: 62px
    }

    .screen-ready__text {
        margin: 0 0 35px;
        font-size: 25px
    }
    .screen-ready__small-text {
        font-size: 20px
    }
}

@media (max-width: 991.98px) {
    .screen-ready {
        padding:50px 0
    }

    .screen-ready__title {
        font-size: 54px
    }

    .screen-ready__text {
        margin: 0 0 25px;
        font-size: 20px
    }
    .screen-ready__small-text {
        font-size: 16px
    }
}

@media (max-width: 767.98px) {
    .screen-ready {
        padding:40px 0
    }

    .screen-ready__title {
        font-size: 40px
    }

    .screen-ready__text {
        margin: 0 0 20px;
        font-size: 16px
    }
    .screen-ready__small-text {
        font-size: 14px
    }
}

@media (max-width: 575.98px) {
    .screen-ready {
        padding:30px 0
    }

    .screen-ready__title {
        font-size: 30px
    }

    .screen-ready__text {
        margin: 0 0 15px;
        font-size: 16px
    }
}

.footer {
    margin-top: auto;
    margin-bottom: 0;
    padding: 50px 0;
    text-align: center;
    background-color: #ffffff
}

.footer-nav-ul {
    margin: 0 auto 10px;
    padding: 0;
    list-style: none
}

.footer-nav-ul-li {
    margin: 10px
}

.footer-nav-ul-li__link {
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    font-size: 18px;
    color: #383838
}

.footer__copyright {
    font-size: 12px;
    color: #383838
}

@media (max-width: 1249.98px) {
    .footer {
        padding:20px 0 30px
    }

    .footer-nav-ul-li {
        margin: 5px 10px
    }

    .footer-nav-ul-li__link {
        padding: 5px;
    }
}

@media (max-width: 767.98px) {
    .footer {
        padding:10px 0 15px
    }

    .footer-nav-ul {
        margin-bottom: 5px
    }

    .footer-nav-ul-li {
        margin: 3px
    }

    .footer-nav-ul-li__link {
        padding: 2px;
        font-size: 13px
    }

}

.screen-prices {
    padding-bottom: 45px;
    text-align: center;
    background-color: #ffffff
}

.screen-prices__title {
    margin: 0 auto 25px;
    font-size: 38px;
    font-weight: 300;
    color: #343a40
}

.screen-prices__text {
    margin: 0 auto 50px;
    width: 100%;
    max-width: 870px;
    text-align: center;
    font-size: 23px;
    line-height: 23px;
    font-weight: 300;
    color: #343a40
}

.screen-prices__text .bold {
    font-weight: 700
}

.prices {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}
.prices__price {
    position: relative;
    z-index: 2;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.prices__price:not(:last-of-type) {
    border-right: 1px solid #b4b8c1
}

.price {
    position: relative;
}

.price.plan-onetime.hide {
    /*position: relative;
    opacity: 0.5;*/
}

.price_most-popular {
    -webkit-box-shadow: 0 5px 29px 0 rgba(40,44,49,.13);
    box-shadow: 0 5px 29px 0 rgba(40,44,49,.13)
}

.price_most-popular .price__most-popular {
    display: inline-block
}

.price__content {
    position: relative;
    z-index: 1;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 15px 15px 30px;
    width: 100%;
    background-color: #fff
}
.price-check-icon{
    background: url(../images/price-check-icon.png) no-repeat;
    width: 23px;
    height: 17px;
    display: block;
    margin:0 auto;
}
.pricing-table-list {
    list-style: none;
    padding: 0;
    margin: 0;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
.pricing-table-bottom {
    -webkit-box-shadow: 0 3px 4px 2px #f1f1f1;
    box-shadow: 0 3px 4px 2px #f1f1f1;
}
.pricing-table-top-text{
    -webkit-box-shadow: 0 0px 4px 2px #f1f1f1;
    box-shadow: 0 0px 4px 2px #f1f1f1;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}
.pricing-table-top-text:first-child,.pricing-table-list:first-child{border-right:1px solid #f1f1f1;}
/*.pricing-table-top-text:last-child,.pricing-table-list:last-child{margin-left: 5px;}
*/.pricing-table-top-text:first-child{border-top:9px solid #f1c310;margin-top: 50px;}
.pricing-table-top-text:last-child{border-top:9px solid #4d37be;}
.pricing-table-list-text{text-align: left;}
.pricing-table{
    width: 100%;
    max-width: 100%;
    margin-bottom: 30px;
    border-collapse: separate;
    border-spacing: 0.5em;
    position: relative;
    
}
.pricing-table-list li:nth-of-type(odd) {
    background-color: #f9f9f9;
}
.pricing-table-list li {
    padding: 1rem;
    vertical-align: top;
    font-size: 18px;
    height: 50px;
}

.price_features_title{
    text-align: left;
    font-size: 28px;
    color:#383838;
    font-weight: 700;
    margin-bottom: 25px;
    padding-left:1rem;
}
.price__title {
    position: relative;
    z-index: 1;
    margin: 0;
    font-size: 24px;
    line-height: 50px;
    font-weight: 400;
    color: #fff;
    background-color: #66bbf6
}

.price__most-popular {
    position: relative;
    display: none;
    margin-bottom: 18px;
    padding-bottom: 14px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    color: #7e7e7e
}

.price__most-popular::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 85%;
    height: 4px;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #7fd16b
}

.price__text-top {
    margin: 0 auto 20px;
    min-height: 32px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #5c6268
}

.price-cost {
    margin-bottom: 15px;
    font-size: 62px;
    font-weight: 400;
/*    color: #4d37be;
*/    line-height: 1;
}
.price__text,
.price-cost__period {
    font-size: 16px;
    font-weight: 500;
    color:#383838;
    margin-bottom:0;
}

.price-cost__period-small {
    font-size: 12px;
    font-weight: 400;
    color: #7e7e7e
}

.price__campaign {
    margin: 0 auto 15px;
    padding-bottom: 20px;
    width: 90%;
    font-size: 20px;
    font-weight: 300;
    color: #5c6268;
    border-bottom: 1px solid rgba(5,5,5,.1)
}

.price-features {
    margin: 0 0 40px;
    padding: 0;
    min-height: 54px;
    list-style: none
}

.price-features__li {
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #5c6268
}

.price .btn {
    padding: 0;
    min-width: auto;
    min-height: auto;
    width: 50%;
    height: auto;
    font-size: 17px;
    font-weight: 400;
    line-height: 50px
}

@media (max-width: 1249.98px) {
    .price__text-top {
        margin-bottom: 10px;
        min-height: 48px
    }

    .price__campaign {
        padding-bottom: 15px;
        font-size: 18px;
        width: 100%
    }

    .price-features {
        margin-bottom: 30px
    }

    .price .btn {
        line-height: 44px
    }
    .pricing-table-list li{
        font-size: 16px;
    }
}

@media (max-width: 991.98px) {
    .screen-prices__title {
        margin-bottom:20px;
        font-size: 26px
    }

    .screen-prices .period-tabs-nav {
        margin-bottom: 25px
    }

    .price__title {
        font-size: 20px
    }

    .price__text-top {
        margin-bottom: 10px;
        min-height: 48px;
        font-size: 13px
    }

    .price__campaign {
        margin-bottom: 10px;
        padding-bottom: 10px;
        font-size: 14px;
        width: 100%
    }

    .price-features {
        margin-bottom: 20px
    }

    .price-features__li {
        font-size: 12px
    }

    .price .btn {
        font-size: 16px;
        line-height: 36px
    }

    .pricing-table-list li{font-size: 14px;}
}

@media (max-width: 767.98px) {
    .screen-prices__title {
        margin-bottom:15px;
        font-size: 22px
    }

    .screen-prices .period-tabs-nav {
        margin-bottom: 15px
    }

    .prices {
        padding-top: 15px
    }
    .price_features_title{font-size: 22px;padding-left: 0.5rem;}
    .pricing-table-list li{font-size: 13px;padding-left: 0.5rem;padding-right: 0.5rem;}
}

@media (min-width: 575.98px) and (max-width:767.98px) {
    .price__title {
        font-size:16px;
        line-height: 35px
    }

    .price__most-popular {
        margin-bottom: 10px;
        padding-bottom: 3px;
        font-size: 12px
    }

    .price__most-popular::after {
        height: 2px
    }

    .price__text-top {
        min-height: 64px;
        font-size: 12px
    }

    .price-cost__period {
        font-size: 10px
    }

    .price-cost__period-small {
        font-size: 10px
    }

    .price__campaign {
        font-size: 11px
    }

    .price-features__li {
        font-size: 10px
    }
}

@media (max-width: 575.98px) {
    .prices {
        padding-top:10px
    }

    .prices::before {
        display: none
    }

    .price .btn {
        width: auto;
        min-height: 40px;
        padding: 10px;
        font-size: 17px;
        line-height: 1.2
    }
    
    .price-cost{font-size: 42px;}
    .pricing-table-list li{height: 60px;}
}
.screen-faq{
    background: rgb(255,255,255);
    background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(245,245,245,1) 47%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(245,245,245,1) 47%,rgba(255,255,255,1) 100%);
    background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(245,245,245,1) 47%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 );
    padding-bottom: 45px;
}
.faq-list {
    margin: 80px auto 90px;
    max-width: 650px;
    width: 100%
}

.faq-list-question {
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    font-weight: 400;
    font-size: 20px;
    color: #2d3963
}

.faq-list-question:not(:first-of-type) {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #dbdbdb
}

.faq-list-question__plus {
    position: relative;
    display: inline-block;
    margin-top: 5px;
    margin-right: 20px;
    width: 20px;
    height: 20px;
    background-color: #dbdbdb;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.faq-list-question__plus::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 3px;
    background-color: #fff;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.faq-list-question__plus::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3px;
    height: 50%;
    background-color: #fff;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.faq-list-question.is-active .faq-list-question__plus {
    background-color: #4d37be
}

.faq-list-question.is-active .faq-list-question__plus::before {
    -webkit-transform: translate(-50%,-50%) rotate(180deg);
    -ms-transform: translate(-50%,-50%) rotate(180deg);
    transform: translate(-50%,-50%) rotate(180deg)
}

.faq-list-question.is-active .faq-list-question__plus::after {
    opacity: 0;
    -webkit-transform: translate(-50%,-50%) rotate(180deg);
    -ms-transform: translate(-50%,-50%) rotate(180deg);
    transform: translate(-50%,-50%) rotate(180deg)
}

.faq-list-question:hover .faq-list-question__plus {
    background-color: #4d37be
}

.faq-list-question__text {
    display: inline-block
}

.faq-list__answer {
    display: none;
    padding-top: 20px;
    font-size: 16px;
    line-height: 23px;
    color: #5c6268
}

.block-join {
    text-align: center
}

.block-join__text {
    margin-bottom: 30px;
    font-size: 30px;
    line-height: 40px;
    color: #323232
}

.block-join .btn {
    margin-bottom: 15px
}

.block-join__small-text {
    fotn-size: 22px;
    line-height: 24px;
    color: #323232
}

@media (max-width: 991.98px) {
    .faq-list {
        margin:50px auto 70px
    }

    .faq-list-question:not(:first-of-type) {
        margin-top: 20px;
        padding-top: 20px
    }

    .faq-list-question__text {
        font-size: 20px
    }

    .faq-list__answer {
        padding-top: 15px
    }

    .block-join__text {
        font-size: 24px;
        line-hegiht: 32px
    }
}

@media (max-width: 575.98px) {
    .faq-list {
        margin:35px auto 50px
    }

    .faq-list-question {
        font-size: 18px
    }

    .faq-list-question:not(:first-of-type) {
        margin-top: 15px;
        padding-top: 15px
    }

    .faq-list-question__plus {
        min-width: 20px;
        margin-top: 0;
        margin-right: 10px
    }

    .faq-list-question__text {
        font-size: 16px
    }

    .faq-list__answer {
        padding-top: 10px
    }

    .block-join__text {
        font-size: 20px;
        line-hegiht: 28px
    }
}
.screen-pricing__title,
.screen-support__title {
    display: block;
    margin: 0 auto 60px;
    width: 100%;
    font-size: 43px;
    line-height: 55px;
    font-weight: 700;
    color: #383838
}

.screen-support #step-1 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    min-height: 650px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 25px 0 25px;
    background-color: #ffffff
}

.screen-support #step-1 .support-form-label {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 25px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 60px 10px;
    width: 365px;
    min-height: 250px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    background-color: #fff;
    -webkit-box-shadow: 0 3px 10px 0 rgba(0,0,0,0.15);
    box-shadow: 0 3px 10px 0 rgba(0,0,0,0.15);
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
    cursor: pointer;
    border:2px solid #ffffff;
}

.screen-support #step-1 .support-form-label:not(:last-of-type) {
    margin-right: 25px
}

.screen-support #step-1 .support-form-label:hover .support-form-label__icon {
    fill: #4d37be
}

.screen-support #step-1 .support-form-label__input {
    display: none
}
.screen-support #step-1 .support-form-label:hover,
.screen-support #step-1 .support-form-label.is-checked {
    /*-webkit-box-shadow: 0 24px 43px 0 rgba(0,0,0,0.15);
    box-shadow: 0 24px 43px 0 rgba(0,0,0,0.15);*/
    background-color: #f9f9f9;
}

.screen-support #step-1 .support-form-label.is-checked .support-form-label__icon {
    fill: #4d37be
}

.screen-support #step-1 .support-form-label__icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    max-width: 50px;
    max-height: 40px;
    fill: #dbdbdb;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.screen-support #step-1 .support-form-label__text {
    font-size: 20px;
    line-height: 23px;
    font-weight: 600;
    color: #383838
}

.screen-support #step-2,.screen-support #step-3 {
    display: none;
    padding: 95px 0
}

.screen-support #step-2 .container,.screen-support #step-3 .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.screen-support #step-2 .btn,.screen-support #step-3 .btn {
    font-size: 18px;
    border: 0;
    cursor: pointer
}

.screen-support #step-2 .support-form__description {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    width: -webkit-calc(90% - 240px);
    width: calc(90% - 240px);
    font-size: 20px;
    border: 0;
    border-bottom: 1px solid #b5bdd5;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.screen-support #step-2 .support-form__description::-webkit-input-placeholder {
    color: #383838
}

.screen-support #step-2 .support-form__description::-moz-placeholder {
    color: #383838
}

.screen-support #step-2 .support-form__description:-ms-input-placeholder {
    color: #383838
}

.screen-support #step-2 .support-form__description::-ms-input-placeholder {
    color: #383838
}

.screen-support #step-2 .support-form__description::placeholder {
    color: #383838
}

.screen-support #step-2 .support-form__description:focus,.screen-support #step-2 .support-form__description:hover {
    color: #383838;
    border-color: #b5bdd5
}

.screen-support #step-3 .support-form__email {
    width: -webkit-calc(50% - 240px);
    width: calc(50% - 240px);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 45px;
    font-size: 18px;
    background-color: #f5f5f5;
    border: 0;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s;
    color: #374568
}

.screen-support #step-3 .support-form__email::-webkit-input-placeholder {
    color: #374568
}

.screen-support #step-3 .support-form__email::-moz-placeholder {
    color: #374568
}

.screen-support #step-3 .support-form__email:-ms-input-placeholder {
    color: #374568
}

.screen-support #step-3 .support-form__email::-ms-input-placeholder {
    color: #374568
}

.screen-support #step-3 .support-form__email::placeholder {
    color: #374568
}

@media (max-width: 1250px) {
    .screen-support #step-1 {
        min-height:450px
    }

    .screen-support #step-1 .support-form-label {
        padding: 50px 10px;
        width: 290px;
        min-height: 150px;
    }

    .screen-support #step-1 .support-form-label:not(:last-of-type) {
        margin-right: 15px
    }

    .screen-support #step-1 .support-form-label__icon {
        max-wdith: 40px;
        max-height: 35px;
        margin-right: 5px
    }

    .screen-support #step-1 .support-form-label__text {
        font-size: 17px
    }
}

@media (max-width: 992px) {
    .screen-support__title,.screen-pricing__title {
        margin:0 auto 40px;
        font-size: 32px
    }

    .screen-support #step-1 {
        min-height: 350px
    }

    .screen-support #step-1 .support-form-label {
        padding: 40px 10px;
        width: 220px
    }

    .screen-support #step-1 .support-form-label:not(:last-of-type) {
        margin-right: 10px
    }

    .screen-support #step-1 .support-form-label__icon {
        max-wdith: 35px;
        max-height: 30px;
        margin-right: 5px
    }

    .screen-support #step-1 .support-form-label__text {
        font-size: 16px
    }

    .screen-support #step-2,.screen-support #step-3 {
        padding: 65px 0
    }

    .screen-support #step-2 .btn,.screen-support #step-3 .btn {
        min-width: 200px;
        font-size: 18px
    }

    .screen-support #step-3 .support-form__email {
        width: -webkit-calc(90% - 200px);
        width: calc(90% - 200px)
    }
}

@media (max-width: 768px) {
    .screen-support__title,.screen-pricing__title  {
        margin:0 auto 30px;
        font-size: 30px
    }

    .screen-support #step-1 {
        min-height: 250px
    }

    .screen-support #step-1 .support-form-label {
        margin-bottom: 10px;
        padding: 30px 10px;
        width: 220px
    }

    .screen-support #step-1 .support-form-label:not(:last-of-type) {
        margin-right: 10px
    }

    .screen-support #step-1 .support-form-label__icon {
        max-wdith: 35px;
        max-height: 30px;
        margin-right: 5px
    }

    .screen-support #step-1 .support-form-label__text {
        font-size: 16px
    }

    .screen-support #step-2,.screen-support #step-3 {
        padding: 45px 0
    }

    .screen-support #step-2 .btn,.screen-support #step-3 .btn {
        min-width: 150px;
        font-size: 16px
    }

    .screen-support #step-2 .support-form__description {
        width: -webkit-calc(100% - 150px);
        width: calc(100% - 150px);
        font-size: 18px
    }

    .screen-support #step-3 .support-form__email {
        padding: 0 10px;
        width: -webkit-calc(100% - 150px);
        width: calc(100% - 150px);
        font-size: 18px
    }
}

@media (max-width: 576px) {
    .screen-support__title,.screen-pricing__title  {
        margin:0 auto 20px;
        font-size: 26px;
        line-height: 28px
    }

    .screen-support #step-1 {
        padding-top: 55px;
        min-height: 250px
    }

    .screen-support #step-1 .support-form-label {
        margin-bottom: 10px;
        padding: 25px 10px;
        width: 100%;
        min-height:auto;
    }

    .screen-support #step-1 .support-form-label:not(:last-of-type) {
        margin-right: 0
    }

    .screen-support #step-2,.screen-support #step-3 {
        padding: 35px 0
    }

    .screen-support #step-2 .container,.screen-support #step-3 .container {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .screen-support #step-2 .btn,.screen-support #step-3 .btn {
        min-width: 150px;
        font-size: 16px
    }

    .screen-support #step-2 .support-form__description {
        display: block;
        padding: 15px 10px;
        width: 100%;
        margin: 10px;
        font-size: 16px;
        height: 40px
    }

    .screen-support #step-3 .support-form__email {
        display: block;
        padding: 15px 10px;
        width: 100%;
        margin: 10px;
        font-size: 16px;
        height: 40px
    }
}

.screen-404 {
    position: relative;
    width: 100%;
    height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-image: -webkit-linear-gradient(63deg,#5eaffb 0,#73cae6 100%,#396fb2 100%,#f5f5f9 100%);
    background-image: -o-linear-gradient(63deg,#5eaffb 0,#73cae6 100%,#396fb2 100%,#f5f5f9 100%);
    background-image: linear-gradient(27deg,#5eaffb 0,#73cae6 100%,#396fb2 100%,#f5f5f9 100%);
    overflow: hidden
}

.screen-404 .container {
    position: relative;
    z-index: 2;
    text-align: center
}

.screen-404__title {
    text-align: center;
    font-size: 180px;
    font-weight: 700;
    color: #fff
}

.screen-404__text {
    margin-bottom: 45px;
    text-align: center;
    font-size: 30px;
    font-weight: 300;
    color: #fff
}

.screen-404 .btn_white {
    color: #5ca6ed
}

.screen-404 .btn_white:hover {
    color: #1773cc
}

.screen-404__404 {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    text-align: center;
    font-size: 40rem;
    font-weight: 700;
    color: #70c8f1;
    text-shadow: 0 22px 51px rgba(48,90,131,.09);
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%)
}

@media (max-width: 1400px) {
    .screen-404__title {
        line-height:180px
    }

    .screen-404__text {
        margin-bottom: 25px
    }

    .screen-404__404 {
        font-size: 35rem
    }
}

@media (max-width: 1200px) {
    .screen-404__title {
        font-size:160px;
        line-height: 160px
    }

    .screen-404__text {
        margin-bottom: 25px
    }

    .screen-404__404 {
        font-size: 30rem
    }
}

@media (max-width: 992px) {
    .screen-404__title {
        font-size:120px;
        line-height: 120px
    }

    .screen-404__text {
        margin-bottom: 25px;
        font-size: 26px
    }

    .screen-404__404 {
        font-size: 25rem
    }
}

@media (max-width: 768px) {
    .screen-404__title {
        font-size:80px;
        line-height: 80px
    }

    .screen-404__text {
        margin-bottom: 20px;
        font-size: 22px
    }

    .screen-404__404 {
        font-size: 20rem
    }
}

@media (max-width: 576px) {
    .screen-404__title {
        font-size:60px;
        line-height: 60px
    }

    .screen-404__text {
        margin-bottom: 20px;
        font-size: 20px
    }

    .screen-404__404 {
        font-size: 10rem
    }
}

.screen-form {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f2f2f2;
    /*background-image: -webkit-linear-gradient(63deg,#5eaffb 0,#73cae6 100%,#396fb2 100%,#f5f5f9 100%);
    background-image: -o-linear-gradient(63deg,#5eaffb 0,#73cae6 100%,#396fb2 100%,#f5f5f9 100%);
    background-image: linear-gradient(27deg,#5eaffb 0,#73cae6 100%,#396fb2 100%,#f5f5f9 100%);*/
    overflow: hidden
}

.screen-form__logo {
    margin: 0px;
}

.screen-form .container {
    padding-top: 0px;
    padding-bottom: 50px;
    margin: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center
}

.screen-form__text-before {
    margin-bottom: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #383838
}

.screen-form__text-after {
    margin-top: 10px;
    text-align: center;
    font-size: 12px;
    line-height: 1.4;
    color: #383838
}

.screen-form__white-link {
    font-size: 12px;
    line-height: 1.4;
    color: #383838;
    text-decoration: underline
}

.single-form {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 35px 35px 40px;
    max-width: 400px;
    width: -webkit-calc(100% - 20px);
    width: calc(100% - 20px);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 29px 51px 0 #f1f1f1;
    box-shadow: 0 29px 51px 0 #f1f1f1
}

.single-form__title {
    margin-bottom: 45px;
    text-align: center;
    font-size: 50px;
    line-height: 1.2;
    font-weight: 300;
    color: #383838
}

.single-form__title .small {
    display: block;
    font-size: 16px;
    line-height: 1.2;
    color: #383838
}

.single-form-label {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 35px
}
.single-form-label.has-error {flex-wrap: wrap;}
.single-form-label.has-error .help-block {
    width: 100%;
    font-size: 11pt;
    color: #f00;
    padding-top: 10px;
}

.single-form-label:focus .single-form-label__icon svg,.single-form-label:hover .single-form-label__icon svg {
    fill: #4d37be
}

.single-form-label:focus .single-form-label__input,.single-form-label:hover .single-form-label__input {
    color: #4d37be;
    border-color: #4d37be
}

.single-form-label__icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
    max-width: 25px;
    max-height: 25px;
    width: auto;
    height: auto
}

.single-form-label__icon svg {
    width: 25px;
    height: 25px;
    fill: #dbdbdb;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.single-form-label__input {
    width: 100%;
    height: 45px;
    font-size: 15px;
    font-weight: 300;
    border: 0;
    border-bottom: 1px solid #b5bed5;
    background-color: transparent;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.single-form-label__input::-webkit-input-placeholder {
    color: #acacac
}

.single-form-label__input::-moz-placeholder {
    color: #acacac
}

.single-form-label__input:-ms-input-placeholder {
    color: #acacac
}

.single-form-label__input::-ms-input-placeholder {
    color: #acacac
}

.single-form-label__input::placeholder {
    color: #acacac
}

.single-form-label__input:focus,.single-form-label__input:hover {
    color: #4d37be;
    border-color: #4d37be
}

.single-form-check-label {
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 300;
    color: #5b6268;
    cursor: pointer
}

.single-form-check-label__pseudo-input {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    width: 25px;
    height: 25px;
    background-color: #4d37be;
    -webkit-border-radius: 4px;
    border-radius: 4px
}

.single-form-check-label__pseudo-input::before {
    content: '';
    position: absolute;
    top: 40%;
    left: 50%;
    z-index: 1;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 10px;
    height: 15px;
    border-right: 4px solid #fff;
    border-bottom: 4px solid #fff;
    -webkit-transform: translate(-50%,-50%) rotate(45deg);
    -ms-transform: translate(-50%,-50%) rotate(45deg);
    transform: translate(-50%,-50%) rotate(45deg);
    opacity: 0;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.single-form-check-label__input {
    display: none
}

.single-form-check-label__input:checked+.single-form-check-label__pseudo-input::before {
    opacity: 1
}

.single-form__row {
    margin-bottom: 45px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.single-form__link {
    font-size: 13px;
    font-weight: 400;
    color: #2d3963;
    text-decoration: underline;
    margin-top: -5px;
}

.single-form .btn {
    margin-bottom: 25px;
    padding: 0;
    width: 100%;
    font-size: 20px;
    min-height: 50px;
    border: 0;
    cursor: pointer;
}

.single-form-bottom {
    text-align: center;
    font-size: 15px;
    line-height: 20px;
    color: #2d3963
}

.single-form-bottom__link {
    color: #4d37be;
    text-decoration: underline
}
@media (max-width: 991.98px){
    .header_form {
        position: static;
        text-align: center;
    }
    .screen-form__logo .header-logo__img{
        margin: 10px auto;
    }
}
@media (max-width: 576px) {
    .screen-form__text-before {
        font-size:18px
    }

    .single-form {
        padding: 25px 15px 30px;
        width: 100%;
        max-width: 100%;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    .screen-form .container{padding-top: 0;margin-top: 10px;}
    .single-form__title {
        margin-bottom: 25px;
        font-size: 35px;
    }

    .single-form__title .small {
        font-size: 14px
    }

    .single-form-label {
        margin-bottom: 20px
    }

    .single-form-label__icon {
        max-width: 20px;
        max-height: 20px;
        margin-right: 10px
    }

    .single-form-check-label__pseudo-input {
        width: 20px;
        height: 20px;
        margin-right: 5px
    }

    .single-form-check-label__pseudo-input::before {
        width: 7px;
        height: 12px
    }

    /*.single-form__link {
        margin-top: 15px;
        margin-left: 30px;
        width: 100%
    }*/

    .single-form__row {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-bottom: 25px
    }

    .single-form .btn {
        margin-bottom: 15px
    }
}

.screen-order {
    border-bottom: 1px solid #b5bdd5;
    background-color: #f2f2f2
}

.screen-order form .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.screen-order-fields {
    margin-right: 8%;
    width: 58%
}

.screen-order-info {
/*    padding-top: 45px;
*/    width: -webkit-calc(100% - 58% - 8%);
    width: calc(100% - 58% - 8%)
}

.screen-order-info .btn {
    margin-bottom: 25px;
    min-width: auto;
    width: 100%;
    font-size: 18px;
    border: 0
}

.screen-order-info .btn img {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px
}

.screen-order__text {
    margin-bottom: 30px;
    font-size: 18px;
    color: #383838
}

.screen-order__title {
    margin: 0 0 15px;
    font-size: 25px;
    font-weight: 600;
    color: #2d3963
}

.screen-order__block {
    margin-bottom: 35px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 30px 45px;
    background-color: #fff;
    -webkit-box-shadow: 0 12px 35px 0 #eeeeee;
    box-shadow: 0 12px 35px 0 #eeeeee
}

.screen-order__block_payment {
    padding: 52px 45px
}

.screen-order-summary-table {
    margin-bottom: 25px;
    width: 100%;
    background-color: #fff;
    -webkit-box-shadow: 0 12px 35px 0 #eeeeee;
    box-shadow: 0 12px 35px 0 #eeeeee
}

.screen-order-summary-table th {
    padding: 0 45px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 18px;
    line-height: 26px
}

.screen-order-summary-table td {
    padding: 0 45px;
    text-align: right;
    font-size: 14px;
    line-height: 30px;
    color: #5c6268
}

.screen-order-summary-table tbody tr:first-of-type td,.screen-order-summary-table tbody tr:first-of-type th {
    padding-top: 30px
}

.screen-order-summary-table tbody tr:last-of-type td,.screen-order-summary-table tbody tr:last-of-type th {
    padding-bottom: 20px
}

.screen-order-summary-table tfoot tr {
    background-color: #d5d5db
}

.screen-order-summary-table tfoot td {
    padding: 10px 45px;
    font-size: 18px;
    color: #383838;
    font-weight: 700
}

.screen-order-summary-table tfoot td:first-of-type {
    text-align: left
}

.screen-order-table {
    width: 100%
}

.screen-order-table th {
    width: 80%;
    font-size: 18px
}

.screen-order-table th:nth-of-type(2) {
    width: auto;
    text-align: center
}

.screen-order-table td {
    width: 80%;
    font-size: 14px;
    color: #383838
}

.screen-order-table td:nth-of-type(2) {
    width: auto;
    text-align: center
}

.screen-order-images__img {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 5px 0
}

.screen-order-text {
    font-size: 14px
}

.screen-order-text__link {
    font-size: 14px;
    text-decoration: underline;
    color: #4d37be;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.screen-order-text__link:hover {
    color: #151b2e
}

.payment {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.payment__fields {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%
}

.payment__or {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 10px
}

.payment__or::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 1;
    width: 1px;
    height: 100%;
    background-color: #b5bdd5;
    -webkit-transform: transateX(-50%);
    -ms-transform: transateX(-50%);
    transform: transateX(-50%)
}

.payment__or span {
    position: relative;
    z-index: 2;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
    font-size: 18px;
    line-height: 1;
    background-color: #fff
}

.payment-pay-pal {
    min-width: 100px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    font-size: 14px;
    color: #3d3d3d
}

.payment-pay-pal__link {
    margin-top: 10px;
    display: inline-block
}

.payment-pay-pal__img {
    display: inline-block;
    max-width: 100%;
    width: 100px;
    height: auto
}

.payment-input {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 10px;
    height: 36px;
    fotn-size: 15px;
    color: #3d3d3d;
    border: 1px solid #b5bdd5;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.payment-input:hover {
    border-color: #51acf4
}

.payment-input:focus {
    border-color: #5bdb6a
}

.payment-input_100 {
    width: 100%
}

.payment-label {
    margin-bottom: 25px;
    display: block
}

.payment-label__text {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    font-size: 18px;
    color: #383838
}

.payment-label__text_row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.payment-label__text_row .payment-label-text {
    margin-right: auto
}

.payment-label__text_row .payment-label-images {
    margin: 0 auto
}

.payment-label__text_row .payment-label-images__img {
    margin: 0 5px;
    display: inline-block;
    vertical-align: middle;
    max-wdith: 100%;
    height: auto
}

.payment-label__input {
    display: block;
    width: 100%
}

.payment__row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.block-lock {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.block-lock-lock {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-right: 25px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    min-width: 65px;
    border-right: 1px solid #4d37be
}

.block-lock-lock__img {
    display: inline-block;
    vertical-align: middle;
    max-width: 30px
}

.block-lock-text {
    font-size: 14px;
    line-height: 16px
}

@media (max-width: 1249.98px) {
    .screen-order-fields {
        margin-right:4%;
        width: 60%
    }

    .screen-order-info {
        padding-top: 20px;
        width: -webkit-calc(100% - 60% - 4%);
        width: calc(100% - 60% - 4%)
    }

    .screen-order__title {
        margin-bottom: 10px;
        font-size: 22px
    }

    .screen-order__text {
        font-size: 16px
    }

    .screen-order-table td,.screen-order-table th {
        width: 40%
    }

    .screen-order-table td:first-of-type,.screen-order-table th:first-of-type {
        width: 60%
    }

    .screen-order__block {
        padding: 15px 20px
    }

    .screen-order__block_payment {
        padding: 30px 20px
    }

    .screen-order-summary-table td,.screen-order-summary-table tfoot td,.screen-order-summary-table th {
        padding-left: 20px;
        padding-right: 20px
    }
}

@media (max-width: 991.98px) {
    .screen-order form .container {
        -webkit-box-orient:vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .screen-order-fields,.screen-order-info {
        margin: 0;
        width: 100%
    }
}

@media (max-width: 767.98px) {
    .screen-order-table th {
        font-size:16px
    }

    .payment {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .payment__or::before {
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
        transfrom: translateY(-50%)
    }

    .payment-label {
        width: 100%
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0,0,0,.6);
    opacity: 0;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.overlay_active {
    opacity: 1;
    z-index: 10
}

.overlay__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 50px 0;
    width: 100%;
    min-height: 100vh;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.popup-form {
    position: relative;
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 22px 40px 40px;
    width: 80%;
    min-width: 290px;
    max-width: -webkit-calc(100% - 30px);
    max-width: calc(100% - 30px);
    height: 80vh;
    min-height: 350px;
    max-height: 790px;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #f8f9fa;
    opacity: 0;
    -webkit-transition: .25s;
    -o-transition: .25s;
    transition: .25s
}

.popup-form__close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: 0;
    font-size: 0
}

.popup-form__close::after,.popup-form__close::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 35px;
    height: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: #fff
}

.popup-form__close::before {
    -webkit-transform: translate(-50%,-50%) rotate(45deg);
    -ms-transform: translate(-50%,-50%) rotate(45deg);
    transform: translate(-50%,-50%) rotate(45deg)
}

.popup-form__close::after {
    -webkit-transform: translate(-50%,-50%) rotate(135deg);
    -ms-transform: translate(-50%,-50%) rotate(135deg);
    transform: translate(-50%,-50%) rotate(135deg)
}

.popup-form_active {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    opacity: 1
}

.popup-form__info {
    /*background-image: -webkit-linear-gradient(117deg,#5eaffb 0,#73cae6 100%,#396fb2 100%,#f5f5f9 100%);
    background-image: -o-linear-gradient(117deg,#5eaffb 0,#73cae6 100%,#396fb2 100%,#f5f5f9 100%);
    background-image: linear-gradient(-27deg,#5eaffb 0,#73cae6 100%,#396fb2 100%,#f5f5f9 100%)*/
    background-color:#4d37be;
}

.popup-form__text-big {
    margin-bottom: 10px;
    font-size: 50px;
    font-weight: 700;
    color: #fff
}

.popup-form__text-small {
    font-size: 25px;
    font-weight: 300;
    color: #fff
}

@media (max-width: 1249.98px) {
    .popup-form__text-big {
        font-size:40px
    }
}

@media (max-width: 991.98px) {
    .popup-form__text-big {
        font-size:30px
    }

    .popup-form__text-small {
        font-size: 20px
    }
    .sidebar{position: static;}
    .fixed-top{position: static;}
}

@media (max-width: 767.98px) {
    .popup-form__text-big {
        font-size:25px
    }

    .popup-form__text-small {
        font-size: 18px
    }
}

@media (max-width: 575.98px) {
    .popup-form__text-big {
        font-size:20px
    }

    .popup-form__text-small {
        font-size: 16px
    }
}

@media (max-width: 1249.98px) {
    body {
        -webkit-background-size:100% 500px;
        background-size: 100% 500px
    }
}

@media (max-width: 767.98px) {
    body {
        -webkit-background-size:100% 380px;
        background-size: 100% 380px
    }
    ul.video-play__tabs li{margin:0 10px;}
}
@media (max-width: 360px) {
    ul.video-play__tabs li{margin:0 5px;}
}