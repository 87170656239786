/* XXXX */
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i');
body{font-family: 'Montserrat', sans-serif;}
body.index{background: none;}
.header-logo {
	position: relative;
}
.screen-prices__text{margin-top:50px;}
.btn_shadow::after{
	-webkit-box-shadow: 0 0 30px 15px #2d3963;
    box-shadow: 0 0 30px 15px #2d3963;
    width: 75%;
}
.prices__price:not(:last-of-type){border-color: #d7dae1;}
.period-tabs-nav-li__btn:hover{
	color:#374568;
	background-color:#fff;
}

.faq-list-question__text{
	width: -webkit-calc(100% - 40px);
	width: calc(100% - 40px);
}
.faq-list__answer{margin-left: 40px;}

.payment-label.payment-label-exp iframe {width: 140% !important;}
.error-message {
	font-size: 10pt;
	color: #f00;
}

@media (max-width: 1024px) {
	.header-logo__img img {width: 150px;}
}
@media (max-width: 991.98px){
	.price__campaign{font-size: 18px;}
	
}
@media (max-width: 767.98px){
	.screen-prices__text .bold{display: block;margin-top: 20px;}
}
@media (max-width: 575.98px){
	.screen-main{padding-top: 50px;padding-bottom: 50px;}
	.screen-main-h1{font-size: 30px;}
	.screen-main__p{font-size: 19px;}
	
    .screen-404{
    	-webkit-align-items: start;
        -ms-flex-align: start;
    	align-items: start;
	}
	.screen-404__title{margin-top: 50px;}
	.screen-prices__text{font-size: 20px;}
	.popup-form{height: 70vh;padding: 20px;}
	.screen-prices__text{margin:0px auto;}
	.video-play__button{width: 38px;}
}
@media (max-width:375px){
	.period-tabs-nav-li__btn{min-width: 140px;}
	.screen-main-h1 br{display: none;}
}